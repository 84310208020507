import {$themeBreakpoints} from '@themeConfig'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    notifications: [],
    cartAddress: {
      first_name: '',
      last_name: '',
      address: '',
      address_2: '',
      post_code: '',
      city: '',
      country: '',
      is_company: false,
      company_name: '',
      nip: '',
    },
    customLayoutMenu: [],
    currentCourse: {},
  },
  getters: {
    currentBreakPoint: state => {
      const {windowWidth} = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getNotifications: state => state.notifications,
    getCartAddress: state => state.cartAddress,
    getCustomMenuLayout: state => state.customLayoutMenu,
    getCurrentCourse: state => state.currentCourse,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_CART_ADDRESS(state, address) {
      state.cartAddress = address
    },
    UPDATE_CUSTOM_LAYOUT_MENU(state, menu) {
      state.customLayoutMenu = menu
    },
    UPDATE_CURRENT_COURSE(state, course) {
      state.currentCourse = course
    },
  },
  actions: {
  },
}
