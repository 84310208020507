import axios from '@axios'

export default {
    namespaced: true,
    state: {
        cartItems: [],
        cartItemsCount: 0,
    },
    getters: {
        getCartItems: state => state.cartItems,
        getCartItemsCount: state => state.cartItemsCount,
    },
    mutations: {
        UPDATE_CART_ITEMS_COUNT(state, count) {
            state.cartItemsCount = count
        },
        ADD_ITEMS_TO_CART(state, items) {
            state.cartItems = items
        },
        ADD_TO_CART(state, product) {
            state.cartItems.push(product)
        },
        REMOVE_FROM_CART(state, product) {
            const productIndex = state.cartItems.findIndex(p => p.id === product.id)
            state.cartItems.splice(productIndex, 1)
        },
    },
    actions: {
        fetchCartItems({dispatch, commit}) {
            console.log('fetching..')
            dispatch('fetchCartProducts')
                .then(response => {
                    commit('ADD_ITEMS_TO_CART', response.data.items)
                    commit('UPDATE_CART_ITEMS_COUNT', response.data.items.length)
                }).catch(error => {
                dispatch('refreshCart')
                dispatch('fetchCartProducts')
                    .then(response => {
                        commit('ADD_ITEMS_TO_CART', response.data.items)
                        commit('UPDATE_CART_ITEMS_COUNT', response.data.items.length)
                    })
            })
        },
        refreshCart() {
            axios
                .post('/cart/initialize')
                .then(response => {
                    const {token} = response.data
                    localStorage.setItem('cartToken', token)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getCartToken() {
            let token = localStorage.getItem('cartToken')
            if (!token) {
                axios
                    .post('/cart/initialize')
                    .then(response => {
                        token = response.data.token
                        localStorage.setItem('cartToken', token)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        fetchProducts(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/courses', {params: payload})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchProduct(ctx, {slug}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/course/${slug}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCartProducts() {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .get(`/cart/${token}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addProductInCart(ctx, {productId}) {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .post(`/cart/${token}/addToCart`, {
                        course_id: productId,
                        quantity: 1,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDiscountToCart(ctx, {code}) {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .post(`/cart/${token}/addDiscountCode`, {
                        code,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeProductFromCart(ctx, {productId}) {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .post(`cart/${token}/removeFromCart`, {
                        item_id: productId,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAddressInCart(ctx, {address}) {
            const token = localStorage.getItem('cartToken')
            let address_1 = address.address + " " + (address.address_house ? address.address_house : address.address_flat)
            let address_2 = address.address_2 + " " + (address.address_2_house ? address.address_2_house : address.address_2_flat)
            let address_invoice = address.address_invoice + " " + (address.address_invoice_house ? address.address_invoice_house : address.address_invoice_flat)

            return new Promise((resolve, reject) => {
                axios
                    .post(`order/${token}/makeOrder`, {
                        first_name: address.first_name,
                        last_name: address.last_name,
                        address: address_1,
                        address_2: address_2,
                        address_invoice: address_invoice,
                        post_code: address.post_code,
                        city: address.city,
                        country: address.country,
                        is_company: address.is_company,
                        company_name: address.company_name,
                        is_invoice: address.is_invoice,
                        nip: address.nip,
                        comments: address.comments,
                        exam_year: address.exam_year
                    })
                    .then(response => resolve(response))
                    .catch(response => reject(response))
            })
        },
    },
}
