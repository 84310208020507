import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import ecommerceStoreModule from '@/views/shop/eCommerceStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    domain: 'runbyit.com',
    api_domain: 'profilaktyka-api.runbyit.com',
    admin_domain: 'profilaktyka-admin.runbyit.com',
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
